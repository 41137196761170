import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./ScreeningDisclaimer.css"; // Ensure you have appropriate CSS for this form

const ScreeningDisclaimer = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  // Navigate for routing to different pages
  const navigate = useNavigate();

  // Function to handle click event
  const navToScreening = () => {
    navigate("/screening"); // Navigate to /video route
  };

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  // Array for keeping track of answered questions
  const [cookies, removeCookie] = useCookies([]);
  const [user, setUsername] = useState("");

  useEffect(() => {
    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);

      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // User Interface Components
  return (
    <div className="sd_form">
      <div className="sdform_container">
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Welcome, {user}!
        </h2>
        <h3 style={{ color: "#333", fontSize: "1.2rem" }}>
        To test your eligibility for the experiment, you'll take a screening test. 
        If you score above 70%, you'll be eligible to begin the experiment. 
        If not, you'll be directed to the end page. Good luck!
        </h3>
        <button className="cont_button" onClick={navToScreening}>
          Take Screening Test
        </button>
      </div>
      {/*<ToastContainer />*/}
    </div>
  );
};

export default ScreeningDisclaimer;
