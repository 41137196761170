import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./PostExperiment.css"; // Ensure you have appropriate CSS for this form

function createCheckboxInput({ name, options, onChange }) {
  //Component Return
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="checkbox"
            name={option.name ? option.name : name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createRadioInput({ name, options, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="radio"
            name={name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
            required
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createNumberInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="number"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={() => onChange(name.name, name.label, option.label)}
        required
      />
      <br />
      <br />
    </div>
  );
}

function createTextInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="text"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={(e) => onChange(name.name, name.label, e.target.value)}
      />
      <br />
      <br />
    </div>
  );
}

const PostExperiment = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  // Navigate for routing to different pages
  const navigate = useNavigate();

  // Array for keeping track of answered questions
  const [cookies, removeCookie] = useCookies([]);
  const [inputValue, setInputValue] = useState({});
  const [user, setUsername] = useState("");

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // Verify and Save Username
  useEffect(() => {
    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);

      // Update inputValue state to include the username
      setInputValue({
        ...inputValue,
        username: user, // Set the username here
      });

      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // Keeping track of changes to variables
  const handleOnChange = (qId, qLabel, aLabel) => {
    setInputValue({
      ...inputValue,
      [qId]: {
        Label: qLabel,
        Answer: [aLabel],
      },
    });
  };

  // Keeping track of errors to form
  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  // Success notifier
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
    setTimeout(() => {
      navigate("/experiencequiz"); // Adjust the redirect as needed
    }, 2000); // Adjust timing as needed
  };

  let date = new Date();

  // Submit the form to back-end on Success
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const additionDate = {
        createdDate: String(date.toLocaleDateString('en-US', { timeZone: 'America/Denver' })),
        createdTime: String(date.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })),
      };

      const { data } = await axios.post(
        `${apiUrl}/postexperiment`, // Adjust the endpoint as needed
        { ...inputValue,
          ...additionDate,
         },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
      handleError("An error occurred while submitting the form.");
    }
  };

  /* Custom value storage for checkbox components */
  const innerCheckboxChange = (qId, qLabel, aLabel) => {
    if (
      inputValue[qId] &&
      inputValue[qId].Answer &&
      inputValue[qId].Answer.includes(aLabel)
    ) {
      //Remove it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId].Answer.filter(
            (answer) => answer !== aLabel
          ),
        },
      }));
    } else {
      //Create and/or add to it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId]
            ? [...prevInputValue[qId].Answer, aLabel]
            : [aLabel],
        },
      }));
    }
  };

  // User Interface Components
  return (
    <div className="pesurvey_form">
      <div className="peform_container">
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Post Experiment Survey
        </h2>
        <h3 style={{ color: "#333", fontSize: "1.2rem" }}>
          Please take a few minutes to answer the following questions about your
          experience during the experiment.
        </h3>
        <form onSubmit={handleSubmit}>
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}

          {/* Question Time */}
          {createRadioInput({
            name: {
              name: "q1",
              label: "What factor did you use to select an exploit?",
            },
            options: [
              { label: "Color", value: "q1-1", id: "q1-1" },
              { label: "Likelihood", value: "q1-2", id: "q1-2" },
              { label: "Both Color and Likelihood", value: "q1-3", id: "q1-3" },
              { label: "Other", value: "q1-4", id: "q1-4" },
            ],
            onChange: handleOnChange,
          })}
          {createTextInput({
            name: { name: "q1other", label: "If other, please expand:" },
            option: { id: "q1other", placeholder: "Enter text here" },
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q3",
              label: "How difficult was the password reset task?",
            },
            options: [
              { label: "Very Easy", value: "q3-1", id: "q3-1" },
              { label: "Easy", value: "q3-2", id: "q3-2" },
              { label: "Medium", value: "q3-3", id: "q3-3" },
              { label: "Difficult", value: "q3-4", id: "q3-4" },
              { label: "Very Difficult", value: "q3-5", id: "q3-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q4",
              label: "How difficult was the password crack task?",
            },
            options: [
              { label: "Very Easy", value: "q4-1", id: "q4-1" },
              { label: "Easy", value: "q4-2", id: "q4-2" },
              { label: "Medium", value: "q4-3", id: "q4-3" },
              { label: "Difficult", value: "q4-4", id: "q4-4" },
              { label: "Very Difficult", value: "q4-5", id: "q4-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q2",
              label: "If you had to choose, which task was more difficult?",
            },
            options: [
              { label: "Password Crack", value: "q2-1", id: "q2-1" },
              { label: "Password Reset", value: "q2-2", id: "q2-2" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q6",
              label:
                "How much effort did you expend when completing password reset task?",
            },
            options: [
              { label: "Very Low", value: "q6-1", id: "q6-1" },
              { label: "Low", value: "q6-2", id: "q6-2" },
              { label: "Moderate", value: "q6-3", id: "q6-3" },
              { label: "High", value: "q6-4", id: "q6-4" },
              { label: "Very High", value: "q6-5", id: "q6-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q7",
              label:
                "How much effort did you expend when completing password crack task?",
            },
            options: [
              { label: "Very Low", value: "q7-1", id: "q7-1" },
              { label: "Low", value: "q7-2", id: "q7-2" },
              { label: "Moderate", value: "q7-3", id: "q7-3" },
              { label: "High", value: "q7-4", id: "q7-4" },
              { label: "Very High", value: "q7-5", id: "q7-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q5",
              label: "If you had to choose, which task required more effort?",
            },
            options: [
              { label: "Password Crack", value: "q5-1", id: "q5-1" },
              { label: "Password Reset", value: "q5-2", id: "q5-2" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q8",
              label: "Which task required more time by your estimation?",
            },
            options: [
              { label: "Password Crack", value: "q8-1", id: "q8-1" },
              { label: "Password Reset", value: "q8-2", id: "q8-2" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q9",
              label: "How interesting was the password reset task?",
            },
            options: [
              { label: "Not interesting", value: "q9-1", id: "q9-1" },
              { label: "Slightly interesting", value: "q9-2", id: "q9-2" },
              { label: "Moderately interesting", value: "q9-3", id: "q9-3" },
              { label: "Very interesting", value: "q9-4", id: "q9-4" },
              { label: "Extremely interesting", value: "q9-5", id: "q9-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q10",
              label: "How interesting was the password crack task?",
            },
            options: [
              { label: "Not interesting", value: "q10-1", id: "q10-1" },
              { label: "Slightly interesting", value: "q10-2", id: "q10-2" },
              { label: "Moderately interesting", value: "q10-3", id: "q10-3" },
              { label: "Very interesting", value: "q10-4", id: "q10-4" },
              { label: "Extremely interesting", value: "q10-5", id: "q10-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q11",
              label:
                "Did you switch between password reset and password crack tasks?",
            },
            options: [
              { label: "No", value: "q11-1", id: "q11-1" },
              { label: "Yes", value: "q11-2", id: "q11-2" },
            ],
            onChange: handleOnChange,
          })}
          {createRadioInput({
            name: { name: "q11v2", label: "Why? (Refer to previous Question)" },
            options: [
              { label: "Difficulty", value: "q11v2-1", id: "q11v2-1" },
              { label: "Effort", value: "q11v2-2", id: "q11v2-2" },
              { label: "Time", value: "q11v2-3", id: "q11v2-3" },
              { label: "Boredom/Disinterest", value: "q11v2-4", id: "q11v2-4" },
              { label: "Satisfied", value: "q11v2-5", id: "q11v2-5" },
              { label: "Invested a lot", value: "q11v2-6", id: "q11v2-6" },
              { label: "Frustration", value: "q11v2-7", id: "q11v2-7" },
              {
                label: "Felt close to getting it correct",
                value: "q11v2-8",
                id: "q11v2-8",
              },
              { label: "Other", value: "q11v2-9", id: "q11v2-9" },
            ],
            onChange: handleOnChange,
          })}
          {createTextInput({
            name: { name: "q11other", label: "If other, please expand:" },
            option: { id: "q11other", placeholder: "Enter text here" },
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q12",
              label:
                "Did you ever find yourself staying in a task longer than you should have by your estimation?",
            },
            options: [
              { label: "No", value: "q12-1", id: "q12-1" },
              { label: "Yes", value: "q12-2", id: "q12-2" },
            ],
            onChange: handleOnChange,
          })}
          {createRadioInput({
            name: { name: "q12v2", label: "Why? (Refer to previous Question)" },
            options: [
              { label: "Difficulty", value: "q12v2-1", id: "q12v2-1" },
              { label: "Effort", value: "q12v2-2", id: "q12v2-2" },
              { label: "Time", value: "q12v2-3", id: "q12v2-3" },
              { label: "Boredom/Disinterest", value: "q12v2-4", id: "q12v2-4" },
              { label: "Satisfied", value: "q12v2-5", id: "q12v2-5" },
              { label: "Invested a lot", value: "q12v2-6", id: "q12v2-6" },
              { label: "Frustration", value: "q12v2-7", id: "q12v2-7" },
              {
                label: "Felt close to getting it correct",
                value: "q12v2-8",
                id: "q12v2-8",
              },
              { label: "Other", value: "q12v2-9", id: "q12v2-9" },
            ],
            onChange: handleOnChange,
          })}
          {createTextInput({
            name: { name: "q12other", label: "If other, please expand:" },
            option: { id: "q12other", placeholder: "Enter text here" },
            onChange: handleOnChange,
          })}
          {createTextInput({
            name: { name: "feedback", label: "Any Comments?" },
            option: { id: "feedback", placeholder: "Enter text here" },
            onChange: handleOnChange,
          })}

          <button className="cont_button" type="submit">
            Continue
          </button>
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default PostExperiment;
