import React, { useEffect } from "react";
import Resume from "../images/resumeDummy.png";

const LinuxFolderStructure = ({ autoAssignLFS }) => {
  //console.log("lfs ran");
  useEffect(() => {
    autoAssignLFS(linuxFoldersStructure4Round[1]);
  }, [1]);

  function importAll(r) {
    let files = {};
    r.keys().forEach((item) => {
      const fileName = item.replace("./", "").replace(".txt", "");
      files[fileName] = r(item).default;
    });
    return files;
  }

  const pwdResetFilesContext = require.context(
    "../taskInformation/pwdReset",
    false,
    /\.txt$/
  );
  const pwdResetFiles = importAll(pwdResetFilesContext);

  const pwdCrackFilesContext = require.context(
    "../taskInformation/pwdCrack",
    false,
    /\.txt$/
  );
  const pwdCrackFiles = importAll(pwdCrackFilesContext);

  const resumeFilesContext = require.context(
    "../resumes/resumeExpt",
    false,
    /\.txt$/
  );
  const resumeFiles = importAll(resumeFilesContext);

  const employeeInfoFilesContext = require.context(
    "../employeeContactInfo",
    false,
    /\.txt$/
  );
  const employeeInfoFiles = importAll(employeeInfoFilesContext);

  const cvFilesContext = require.context("../CV_list", false, /\.txt$/);
  const cvFiles = importAll(cvFilesContext);

  const wordlistFilesContext = require.context("../wordlists", false, /\.txt$/);
  const wordlistFiles = importAll(wordlistFilesContext);

  const shadowFilesContext = require.context("../shadowFiles", false, /\.txt$/);
  const shadowFiles = importAll(shadowFilesContext);

  const crackedFilesContext = require.context(
    "../crackedPwdFiles",
    false,
    /\.txt$/
  );
  const crackedFiles = importAll(crackedFilesContext);

  const ancillaryFilesContext = require.context(
    "../ancillary_files",
    false,
    /\.txt$/
  );
  const ancillaryFiles = importAll(ancillaryFilesContext);

  const linuxFoldersStructure4Round = {
    //Round 1 Choice
    //Password Reset -> Easy
    //Password Crack -> Easy
    1: {
      System0: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            mason_lewis: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            alexander_harris: {
              "resume.txt": resumeFiles["alexander_harris"],
            },
          },
          projects: {
            "practice.txt": ["Practice file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "README.txt": [ancillaryFiles["practiceREADME"]],
      },
    },
  };
  return <></>;
};

export default LinuxFolderStructure;
