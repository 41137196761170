import React, { useEffect } from "react";

const SystemVulnerabilities = ({ roundNum, assignVulnerabilities }) => {
  useEffect(() => {
    assignVulnerabilities(systemVulnerabilities4EachRound[roundNum]);
  }, [roundNum]);

  const systemVulnerabilities4EachRound = {
    1: {
      System1: {
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low"],
      },
      System2: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
      },
      System3: {
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
      },
      System4: {
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
      },
      System5: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
      },
      System6: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
      },
    },
    2: {
      System1: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
      },
      System2: {
        type_confusion: [126, "CWE-843", "type_confusion", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
      },
      System3: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
      },
      System4: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "High"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
      },
      System5: {
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium"],
      },
      System6: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
      },
    },
    3: {
      System1: {
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        sql_injection: [3306, "CWE-89", "sql_injection", "High"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
      },
      System2: {
        path_traversal: [21, "CWE-22", "path_traversal", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Medium"],
      },
      System3: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low"],
      },
      System4: {
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Low"],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High"],
      },
      System5: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Low"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
      },
      System6: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
      },
    },
    4: {
      System1: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
      },
      System2: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        type_confusion: [126, "CWE-843", "type_confusion", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
      },
      System3: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
      },
      System4: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
      },
      System5: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
      },
      System6: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "High"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
      },
    },
  };
  return <></>; //<div>SystemVulnerabilities</div>;
};

export default SystemVulnerabilities;

/*

System7: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
      },
      System8: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "High"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Low"],
      },
      System9: {
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
      },



System7: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
      },
      System8: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium"],
      },
      System9: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "High"],
      },


System7: {
        use_after_free: [22, "CWE-416", "use_after_free", "Low"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "High"],
      },
      System8: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
      },
      System9: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "High"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Medium"],
      },


System7: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "High"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low"],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
      },
      System8: {
        use_after_free: [22, "CWE-416", "use_after_free", "Medium"],
        type_confusion: [126, "CWE-843", "type_confusion", "Low"],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium"],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "High"],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low"],
      },
      System9: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low"],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium"],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium"],
        path_traversal: [21, "CWE-22", "path_traversal", "Low"],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High"],
      },


*/
