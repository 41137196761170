import React, { useEffect } from "react";

const PracticeSystemVulnerabilities = ({ assignVulnerabilities }) => {

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    assignVulnerabilities(systemVulnerabilities4EachRound[1]);
  }, [1]);

  const systemVulnerabilities4EachRound = {
    1: {
      System0: {
        attack_script: [1, "CWE-1", "attack_script", "High"],
      },
    },
  };
  return <></>;
};

export default PracticeSystemVulnerabilities;
