//Login.jsx
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./Login.css";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    username: "", // change email to username
    password: "",
  });
  const { username, password } = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) => console.log(msg);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl =
      process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu"; // Use environment variable or default to HTTPS

    try {
      const { data } = await axios.post(
        `${apiUrl}/login`, // Use the apiUrl variable
        { username: inputValue.username, password: inputValue.password },
        { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Invalid Credentials or Network Issue");
    }
    setInputValue({ username: "", password: "" });
  };

  return (
    <div className="login_page">
      <div className="form_container">
        <h2>Login Account</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">Username</label>{" "}
            {/* Change label from Email to Username */}
            <input
              type="text" // Change type from email to text
              name="username" // Change name from email to username
              value={username} // Change value from email to username
              placeholder="Enter your username" // Change placeholder
              onChange={handleOnChange}
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Enter your password"
              onChange={handleOnChange}
            />
          </div>
          <button type="submit" className="cont_button">
            Login
          </button>
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default Login;
