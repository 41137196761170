import { Route, Routes } from "react-router-dom";
import { Login,
  ErrorPage, 
  Signup1, 
  Signup2, 
  Signup3, 
  Game, 
  Home, 
  Game1,
  Game2,
  Game3, 
  Round, 
  Consent1, 
  Consent2, 
  Consent3, 
  Quiz, 
  Instructions, 
  Video, 
  Demographics,
  ExperienceQuiz,
  PostExperiment,
  Screening,
  ThankYou,
  ThankYouPoint,
  ScreeningDisclaimer,
  PracticeRound,
  PracticeGame
} from "./pages";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ErrorPage/>} />
        <Route path="/SAUgrP9b0c" element={<Consent1 />} />
        <Route path="/4LI9ZMbUC6" element={<Consent2 />} />
        <Route path="/079Q806LIr" element={<Consent3 />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signupSAUgr" element={<Signup1 />} />
        <Route path="/signup4LI9Z" element={<Signup2 />} />
        <Route path="/signup079Q8" element={<Signup3 />} />
        <Route path="/game" element={<Game />} />
        <Route path="/gameP9b0c" element={<Game1 />} />
        <Route path="/gameMbUC6" element={<Game2 />} />
        <Route path="/game06LIr" element={<Game3 />} />
        <Route path="/round" element={<Round />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/video" element={<Video />} />
        <Route path="/demographics" element={<Demographics />} />
        <Route path="/experiencequiz" element={<ExperienceQuiz />} />
        <Route path="/postexperiment" element={<PostExperiment />} />
        <Route path="/screening" element={<Screening />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/thankyoupoint" element={<ThankYouPoint />} />
        <Route path="/screeningdisclaimer" element={<ScreeningDisclaimer />} />
        <Route path="/practiceround" element={<PracticeRound />} />
        <Route path="/practicegame" element={<PracticeGame />} />
      </Routes>
    </div>
  );
}

export default App;