import React, { useState } from "react";
import "./Consent.css"; // Assuming this is correct based on your environment



function ErrorPage() {
 
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  return (
    <div className="consent_form">
      {" "}
      {/* This class should match your CSS styling */}
      <div className="form_container">
        {" "}
        {/* This div will get styled by .sign_up .form_container styles */}
          <h2>This Page is not available, if you belive this might be a mistake please contact the system administer</h2>
      </div>
    </div>
  );
}

export default ErrorPage;
