import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  useEffect(() => {
    const verifyCookie = async () => {
      /*
      if (!cookies.token) {
        navigate("/login");
      }
     */

      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);
      return status
        ? console.log(`Hello ${user}`)
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  const [roundNumber, setRoundNumber] = useState(1);
  let totalPoints = 0;

  const randomNumberGenerator0or1 = () => {
    let num = Math.floor(Math.random() * 2);
    console.log("rnd num generator", num);
    return num;
  };

  const Round = () => {
    let colorCondition = ["Neutral", "Minimum"];
    let difficultyCondition = ["Choice", "No Choice"];
    let colorConditionChosen = colorCondition[randomNumberGenerator0or1()];
    let difficultyConditionChosen =
      difficultyCondition[randomNumberGenerator0or1()];
    console.log(colorConditionChosen);
    console.log(difficultyConditionChosen);
    let roundNumberAssignedSet = [1, 2, 3, 4];

    navigate("/round", {
      state: {
        roundNumber,
        roundNumberAssignedSet,
        totalPoints,
        colorConditionChosen,
        difficultyConditionChosen,
      },
    });
  };

  const PracticeRound = () => {
    navigate("/practiceround");
  };

  return (
    <>
      <div className="home_page">
        <h4>
          {" "}
          Welcome <span>{username}</span>
        </h4>
        {/*<button onClick={Logout}>LOGOUT</button>*/}
        {/*<button onClick={Round}>Start</button>*/}
        <button onClick={PracticeRound}>Start Practice</button>
      </div>
    </>
  );
};

export default Home;
