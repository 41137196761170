import React, { useEffect } from "react";
import Resume from "../images/resumeDummy.png";

const LinuxFolderStructure = ({ roundNum, autoAssignLFS }) => {
  //console.log("lfs ran");
  useEffect(() => {
    autoAssignLFS(linuxFoldersStructure4Round[roundNum]);
  }, [roundNum]);

  function importAll(r) {
    let files = {};
    r.keys().forEach((item) => {
      const fileName = item.replace("./", "").replace(".txt", "");
      files[fileName] = r(item).default;
    });
    return files;
  }

  const pwdResetFilesContext = require.context(
    "../taskInformation/pwdReset",
    false,
    /\.txt$/
  );
  const pwdResetFiles = importAll(pwdResetFilesContext);

  const pwdCrackFilesContext = require.context(
    "../taskInformation/pwdCrack",
    false,
    /\.txt$/
  );
  const pwdCrackFiles = importAll(pwdCrackFilesContext);

  const resumeFilesContext = require.context(
    "../resumes/resumeExpt",
    false,
    /\.txt$/
  );
  const resumeFiles = importAll(resumeFilesContext);

  const employeeInfoFilesContext = require.context(
    "../employeeContactInfo",
    false,
    /\.txt$/
  );
  const employeeInfoFiles = importAll(employeeInfoFilesContext);

  const cvFilesContext = require.context("../CV_list", false, /\.txt$/);
  const cvFiles = importAll(cvFilesContext);

  const wordlistFilesContext = require.context("../wordlists", false, /\.txt$/);
  const wordlistFiles = importAll(wordlistFilesContext);

  const shadowFilesContext = require.context("../shadowFiles", false, /\.txt$/);
  const shadowFiles = importAll(shadowFilesContext);

  const crackedFilesContext = require.context(
    "../crackedPwdFiles",
    false,
    /\.txt$/
  );
  const crackedFiles = importAll(crackedFilesContext);

  const ancillaryFilesContext = require.context(
    "../ancillary_files",
    false,
    /\.txt$/
  );
  const ancillaryFiles = importAll(ancillaryFilesContext);

  const linuxFoldersStructure4Round = {
    //Round 1 No Choice
    //Password Reset -> Easy
    1: {
      System1: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            mason_lewis: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            alexander_harris: {
              "resume.txt": resumeFiles["alexander_harris"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
      System2: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            harper_carter: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            derek_simmons: {
              "resume.txt": resumeFiles["derek_simmons"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
      System3: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            elijah_walker: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            liam_smith_1: {
              "resume.txt": resumeFiles["liam_smith_1"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
      System4: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            william_baker: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            mia_wallace: {
              "resume.txt": resumeFiles["mia_wallace"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
      System5: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            felix_wood: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            sophia_turner: {
              "resume.txt": resumeFiles["sophia_turner"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
      System6: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            michael_king: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            mia_thompson: {
              "resume.txt": resumeFiles["mia_thompson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Easy"]],
      },
    },

    //Round 2 No Choice
    //Password Reset -> Hard
    2: {
      System1: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            james_hall: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            heather_morris: {
              "resume.txt": resumeFiles["heather_morris"],
              "employeeInfo.txt": employeeInfoFiles["heather_morris"],
              "CV.txt": cvFiles["heather_morris"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
      System2: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            isabella_clark: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            laura_thompson: {
              "resume.txt": resumeFiles["laura_thompson"],
              "employeeInfo.txt": employeeInfoFiles["laura_thompson"],
              "CV.txt": cvFiles["laura_thompson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
      System3: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            evelyn_nelson: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            olivia_peterson: {
              "resume.txt": resumeFiles["olivia_peterson"],
              "employeeInfo.txt": employeeInfoFiles["olivia_peterson"],
              "CV.txt": cvFiles["olivia_peterson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
      System4: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            leo_smith: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            ethan_parker: {
              "resume.txt": resumeFiles["ethan_parker"],
              "employeeInfo.txt": employeeInfoFiles["ethan_parker"],
              "CV.txt": cvFiles["ethan_parker"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
      System5: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            abigail_ross: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            olivia_kim: {
              "resume.txt": resumeFiles["olivia_kim"],
              "employeeInfo.txt": employeeInfoFiles["olivia_kim"],
              "CV.txt": cvFiles["olivia_kim"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
      System6: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            casey_watson: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            derek_johnson: {
              "resume.txt": resumeFiles["derek_johnson"],
              "employeeInfo.txt": employeeInfoFiles["derek_johnson"],
              "CV.txt": cvFiles["derek_johnson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdResetFiles["Hard"]],
      },
    },

    //Round 3 No Choice
    //Password Crack -> Easy
    3: {
      System1: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            james_hall: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            heather_morris: {
              "resume.txt": resumeFiles["heather_morris"],
              "employeeInfo.txt": employeeInfoFiles["heather_morris"],
              "CV.txt": cvFiles["heather_morris"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
      System2: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            isabella_clark: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            laura_thompson: {
              "resume.txt": resumeFiles["laura_thompson"],
              "employeeInfo.txt": employeeInfoFiles["laura_thompson"],
              "CV.txt": cvFiles["laura_thompson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
      System3: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            evelyn_nelson: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            olivia_peterson: {
              "resume.txt": resumeFiles["olivia_peterson"],
              "employeeInfo.txt": employeeInfoFiles["olivia_peterson"],
              "CV.txt": cvFiles["olivia_peterson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
      System4: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            leo_smith: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            ethan_parker: {
              "resume.txt": resumeFiles["ethan_parker"],
              "employeeInfo.txt": employeeInfoFiles["ethan_parker"],
              "CV.txt": cvFiles["ethan_parker"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
      System5: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            abigail_ross: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            olivia_kim: {
              "resume.txt": resumeFiles["olivia_kim"],
              "employeeInfo.txt": employeeInfoFiles["olivia_kim"],
              "CV.txt": cvFiles["olivia_kim"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
      System6: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            casey_watson: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            derek_johnson: {
              "resume.txt": resumeFiles["derek_johnson"],
              "employeeInfo.txt": employeeInfoFiles["derek_johnson"],
              "CV.txt": cvFiles["derek_johnson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Easy"]],
      },
    },

    //Round 4 No Choice
    //Password Crack -> Hard
    4: {
      System1: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            lily_gonzalez: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            jackson_brown: {
              "resume.txt": resumeFiles["jackson_brown"],
              "employeeInfo.txt": employeeInfoFiles["jackson_brown"],
              "CV.txt": cvFiles["jackson_brown"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
      System2: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            grace_ramirez: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            nathan_wright: {
              "resume.txt": resumeFiles["nathan_wright"],
              "employeeInfo.txt": employeeInfoFiles["nathan_wright"],
              "CV.txt": cvFiles["nathan_wright"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
      System3: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile3"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            gabriel_parker: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            sophia_johnson: {
              "resume.txt": resumeFiles["sophia_johnson"],
              "employeeInfo.txt": employeeInfoFiles["sophia_johnson"],
              "CV.txt": cvFiles["sophia_johnson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
      System4: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile1"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            caleb_hughes: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            jamal_anderson: {
              "resume.txt": resumeFiles["jamal_anderson"],
              "employeeInfo.txt": employeeInfoFiles["jamal_anderson"],
              "CV.txt": cvFiles["jamal_anderson"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow1"],
            "cracked6.txt": crackedFiles["cracked6shadow1"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
      System5: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            aria_rossi: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            liam_smith: {
              "resume.txt": resumeFiles["liam_smith"],
              "employeeInfo.txt": employeeInfoFiles["liam_smith"],
              "CV.txt": cvFiles["liam_smith"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow2"],
            "cracked6.txt": crackedFiles["cracked6shadow2"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
      System6: {
        etc: {
          shadow: { "shadow.txt": shadowFiles["shadowFile2"] },
          passwd: { "passwd.txt": "Content of Password file" },
        },
        home: {
          clients: {
            layla_bell: {
              "privacy_agreement.txt":
                ancillaryFiles["client_privacy_agreement"],
            },
          },
          employees: {
            lucas_martinez: {
              "resume.txt": resumeFiles["lucas_martinez"],
              "employeeInfo.txt": employeeInfoFiles["lucas_martinez"],
              "CV.txt": cvFiles["lucas_martinez"],
            },
          },
          projects: {
            "apple_ui.txt": ["apple_project_file"],
            "android_ui.txt": ["android_project_file"],
          },
          monthly_report: ["Monthly_report_file"],
        },
        usr: {
          wordlists: {
            "sqlmap.txt": wordlistFiles["sqlmap"],
            "hashkiller.txt": wordlistFiles["hashkiller"],
            "crackstation.txt": wordlistFiles["crackstation"],
            "rockyou.txt": wordlistFiles["rockyou"],
            "supercommon.txt": wordlistFiles["supercommon"],
          },
          crackedPwdFiles: {
            "cracked5.txt": crackedFiles["cracked5shadow3"],
            "cracked6.txt": crackedFiles["cracked6shadow3"],
          },
        },

        bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
        "taskREADME.txt": [pwdCrackFiles["Hard"]],
      },
    },
  };
  return <></>;
};

export default LinuxFolderStructure;
