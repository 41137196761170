import React from "react";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./Game.css";
import Resume from "../images/resumeDummy.png";

import Terminal, { ColorMode, TerminalOutput } from "react-terminal-ui";

const Game = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      /* if (!cookies.token) {
      navigate("/login");
      return; // Exit early if no cookie
    } */

      try {
        const { data } = await axios.post(
          `${apiUrl}/ver`,
          {},
          { withCredentials: true }
        );

        const { status, user, email } = data;
        if (status) {
          setUsername(user);
          setUseremail(email);
          toast(`Hello ${user}`, {
            position: "top-right",
          });
        } else {
          removeCookie("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Verification failed:", error);
        removeCookie("token");
        navigate("/login");
      }
    };

    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  // Function to log command to the backend
  const logCommand = async (command, email) => {
    try {
      await axios.post(`${apiUrl}/api/logcommand`, {
        email,
        command,
      });
    } catch (error) {
      console.error("Error logging command:", error);
    }
  };

  const [outsideSystem, setOutsideSystem] = useState(true);

  //Function to handle terminal input
  const handleTerminalInput = (input) => {
    logCommand(input, useremail);

    // Add user input to terminal data
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>{`hacker@local> ${input}`}</TerminalOutput>,
    ]);

    if (outsideSystem) {
      if (input.trim() === "nmap -sL all") {
        listSystems();
      } else if (input.trim().startsWith("use_exploit ")) {
        const nameOfExploit = input.trim().split(" ")[1];
        const nameOfSystem = input.trim().split(" ")[2];
        exploit(nameOfExploit, nameOfSystem);
      } else if (input.trim().startsWith("nmap ")) {
        const systemName = input.trim().split(" ")[1];
        showVulnerabilitiesTable(systemName);
      } else if (input.trim().startsWith("lc")) {
        linuxFolderChecker();
      } else {
        //Handle other commands or show error
        failedCommand();
      }
    } else {
      if (input.trim().startsWith("cd ..")) {
        cdCommandReverse();
      } else if (input.trim().startsWith("cd ")) {
        const systemName = input.trim().split(" ")[1];
        cdCommand(systemName);
      } else if (input.trim().startsWith("cat ")) {
        const fileName = input.trim().split(" ")[1];
        const fileType = input.trim().split(".")[1];
        catCommand(fileName, fileType);
      } else if (input.trim().startsWith("cp ")) {
        const sourceFile = input.trim().split(" ")[1];
        const destinationFile = input.trim().split(" ")[2];
        cpCommand(sourceFile, destinationFile);
      } else if (input.trim() === "ls") {
        lsCommannd();
      } else if (input.trim() === "logout") {
        logoutSystem();
      } else if (input.trim().startsWith("lc")) {
        linuxFolderChecker();
      } else {
        //Handle other commands or show error
        failedCommand();
      }
    }
  };

  //
  const failedCommand = () => {
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        Unknown command. Please run help command to for correct command usage
      </TerminalOutput>,
    ]);
  };

  const linuxFoldersStructure = {
    System1: {
      "testing.txt": ["testingCPCommand"],
      etc: {
        shadow: { "shadowFile.txt": "Content of Shadow file" },
        passwd: ["passwdFile"],
      },
      home: {
        clients: {
          john: ["johnFile"],
          snow: ["snowFile"],
          allsion: ["allison"],
        },
        employees: {
          alice: {
            "resume.png": Resume,
            "employeeInfo.txt": "This is employee content",
          },
          bob: ["resume.txt", "employee_info.txt"],
        },
        projects: {
          apple_ui: ["apple_project_file"],
          android_ui: ["android_project_file"],
        },
        monthly_report: ["Monthly_report_file"],
      },
      usr: { wordlists: ["sqlmap.txt", "rockyou.txt"] },

      bin: { bin1: ["0x001"], bin2: ["0x002"], bin3: ["0x003"] },
      task1README: ["Task 1 README file"],
      task2README: ["Task 2 README file"],
    },
  };

  const linuxFolderChecker = () => {
    console.log(linuxFolders);
  };

  const [linuxFolders, setLinuxFolders] = useImmer(linuxFoldersStructure);
  const [systemFolders, setSystemFolders] = useImmer({});
  const [currentSystemName, setCurrentSystemName] = useState("");
  const [folderLocationTracker, setFolderLocationTracker] = useState([]);
  const [folderLocation, setFolderLocation] = useState(systemFolders);
  useEffect(() => {
    systemFolderToFolderLocationSynchronizer();
  }, [systemFolders]);

  const systemFolderToFolderLocationSynchronizer = () => {
    console.log("system folder synchronized with folder location");
    let folderLocationUpdated = systemFolders;
    for (let i = 0; i < folderLocationTracker.length; i++) {
      folderLocationUpdated = folderLocationUpdated[folderLocationTracker[i]];
    }
    setFolderLocation(folderLocationUpdated);
  };

  const linuxFolderToSystemFolderSynchronizer = () => {
    console.log("linux folder synchronized with system folder");
    setLinuxFolders((draft) => {
      draft[currentSystemName] = systemFolders;
    });
  };

  const cpCommand = (source, destination) => {
    let sourceFolderLocation = source.split("/");
    let contentOfFolderOrFile = systemFolders;
    let sourceFileLocation = "";
    for (let i = 1; i < sourceFolderLocation.length; i++) {
      contentOfFolderOrFile = contentOfFolderOrFile[sourceFolderLocation[i]];
      if (i === sourceFolderLocation.length - 1)
        sourceFileLocation = sourceFolderLocation[i];
    }

    let fileInfo = sourceFileLocation.split(".");
    let fileKey = "";
    let fileChecker = false;
    if (fileInfo[1] === "txt" || fileInfo[1] === "png") {
      fileChecker = true;
      fileKey = sourceFileLocation;
    }

    let destinationPath = destination.split("/");
    if (destinationPath[0] === "." && fileChecker) {
      console.log("This is a file");
      setSystemFolders((draft) => {
        for (let i = 0; i < folderLocationTracker.length; i++) {
          draft = draft[folderLocationTracker[i]];
        }
        draft[fileKey] = contentOfFolderOrFile;
      });
    } else if (destinationPath[0] === "." && !fileChecker) {
      console.log("it is a folder");
      setSystemFolders((draft) => {
        for (let i = 0; i < folderLocationTracker.length; i++) {
          draft = draft[folderLocationTracker[i]];
        }
        let newFileEntries = Object.entries(contentOfFolderOrFile);
        newFileEntries.map(([key, value]) => {
          draft[key] = value;
        });
      });
    }
  };

  const catCommand = (nameOfFile, typeOfFile) => {
    //updated cat command
    if (typeOfFile === "png") {
      let imageHolder = Object.values(folderLocation[nameOfFile]).join("");
      setTerminalLineData((prev) => [
        ...prev,
        <TerminalOutput>
          {<img src={imageHolder} height={800} width={600} />}
        </TerminalOutput>,
      ]);
    } else if (typeOfFile === "txt") {
      let textHolder = Object.values(folderLocation[nameOfFile]).join("");
      setTerminalLineData((prev) => [
        ...prev,
        <TerminalOutput>{textHolder}</TerminalOutput>,
      ]);
    } else {
      failedCommand();
    }
  };

  //This function executes the "cd [folderName]" command
  const cdCommand = (folderName) => {
    //updated command
    setFolderLocation(folderLocation[folderName]);
    setFolderLocationTracker([...folderLocationTracker, folderName]);
  };

  const cdCommandReverse = () => {
    //updated cd .. command
    let startLocation = systemFolders;
    for (let i = 0; i < folderLocationTracker.length - 1; i++) {
      startLocation = startLocation[folderLocationTracker[i]];
    }
    setFolderLocation(startLocation);
    setFolderLocationTracker(folderLocationTracker.slice(0, -1));
  };

  //It executes the "ls"command
  //the command shows all of the children folders or files listed
  //under the current folder location
  const lsCommannd = () => {
    //updated ls command
    let l = Object.keys(folderLocation);
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>{l.join("  ")}</TerminalOutput>,
    ]);
  };

  const listSystems = () => {
    const systemsList = Object.keys(linuxFoldersStructure);
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>{systemsList.join(" ")}</TerminalOutput>,
    ]);
  };

  //List of the vulnerability headings for the table of vulnerabilities
  const vulnerabilityHeadings = [
    "System No.",
    "Port No.",
    "Vulnerability",
    "Exploit Name",
    "Likelihood",
  ];

  //List of vulnerabilities present in each system which can be exploited
  const vulnerabilitySystemIndex = {
    System1: {
      out_of_bounds: [1, 22, "CWE-787", "out_of_bounds", "Medium"],
      cross_site_scripting: [1, 45, "CWE-79", "cross_site_scripting", "High"],
      sql_injection: [1, 24, "CWE-89", "sql_injection", "Medium"],
      improper_input_validation: [
        1,
        126,
        "CWE-20",
        "improper_input_validation",
        "Low",
      ],
    },
  };

  const exploit = (exploitName, systemName) => {
    let vulnerabilities = Object.keys(vulnerabilitySystemIndex[systemName]);
    console.log(vulnerabilities);
    let foundVulnerability = false;
    vulnerabilities.map((vulnerability) => {
      if (vulnerability === exploitName) foundVulnerability = true;
    });
    if (foundVulnerability === true) {
      setCurrentSystemName(systemName);
      setSystemFolders(linuxFolders[systemName]);
      setOutsideSystem(false);
    }
  };

  const logoutSystem = () => {
    linuxFolderToSystemFolderSynchronizer();
    setCurrentSystemName("");
    setSystemFolders({});
    setFolderLocationTracker([]);
    setOutsideSystem(true);
  };

  //Constructs a table with vulnerability heading and all the vulnerabilites
  //associated with the specific system
  const showVulnerabilitiesTable = (systemName) => {
    //lists all of the vulnerabilities present in the system
    let vulnerabilities = Object.entries(vulnerabilitySystemIndex[systemName]);
    //fucntion to make a table with heading and mapping each of the vulnerabilities
    //under the assigned vulnerability heading
    const tableMaker = () => {
      return (
        <table className="table">
          <thead>
            <tr>
              {vulnerabilityHeadings.map((vulnerabilityHeading) => (
                <th key={vulnerabilityHeading}>{vulnerabilityHeading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {vulnerabilities.map(([vulnerabilityNumber, vulnerabilityInfo]) => (
              <tr key={vulnerabilityNumber}>
                <td>{vulnerabilityInfo[0]}</td>
                <td>{vulnerabilityInfo[1]}</td>
                <td>{vulnerabilityInfo[2]}</td>
                <td>{vulnerabilityInfo[3]}</td>
                <td>{vulnerabilityInfo[4]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };
    //adds the table to the terminal data
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>{tableMaker()}</TerminalOutput>,
    ]);
  };

  const [terminalLineData, setTerminalLineData] = useState([
    <TerminalOutput>Welcome to the HackIT simulation!</TerminalOutput>,
  ]);

  return (
    <div>
      <div className="game_page">
        <h1>Let's go {username}</h1>

        <div className="container">
          <Terminal
            className="terminal"
            name="HackIT 2.0"
            colorMode={ColorMode.Dark}
            onInput={handleTerminalInput}
          >
            {terminalLineData}
          </Terminal>
        </div>

        <button onClick={Logout}>LOGOUT</button>
      </div>
    </div>
  );
};

export default Game;
