import React from "react";
import "./Instructions.css"; // Make sure this path matches your project structure
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useEffect } from "react";
import instructions1 from "./instructions_images/HackIT_nmap_all.png";
import instructions2 from "./instructions_images/HackIT_nmap_system.png";
import instructions3 from "./instructions_images/HackIT_use_exploit.png";
import instructions4 from "./instructions_images/HackIT_ls.png";
import instructions5 from "./instructions_images/HackIT_logout.png";
import instructions6 from "./instructions_images/HackIT_cat.png";
import instructions7 from "./instructions_images/HackIT_cp.png";

function Instructions() {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate(); // Hook to get the navigate function

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Function to handle click event
  const navigateToVideo = () => {
    navigate("/video"); // Navigate to /video route
  };

  return (
    <div className="instructions-page">
      <div className="instructions-container">
        <h1 style={{ textAlign: "center" }}>Instructions</h1>
        <h1>Welcome!</h1>
        <p>
        In this study, you will act as a hacker and execute attacks on a server. 
        You will interact with the network with multiple systems, exploit these 
        systems, and complete the tasks detailed in the provided README file. 
        Please note that defenders are monitoring the systems, and attempting 
        to complete the tasks might result in being locked out of the system.
        </p>
        <p>
        First, you must identify different systems available in the network. 
        To obtain the list of active systems, you may run the nmap -sL all command.
        </p>

        <img
          src={instructions1}
          alt="Instructions 1"
          className="instructions-image"
          style={{ height: "60%", width: "40%" }}
        />

        <p>
        You may select the system in the list to conduct reconnaissance. 
        Reconnaissance means that you collect information about the open ports, 
        vulnerabilities that can be exploited and the likelihood of success. 
        The probing of a specific system can be done by the following nmap command.
        </p>

        <img
          src={instructions2}
          alt="Instructions 2"
          className="instructions-image"
          style={{ width: "80%", height: "80%" }}
        />

        <p>
        You will be given multiple ports and associated vulnerabilities as part of the nmap output. You may choose a vulnerability to exploit or search other vulnerabilities in other systems. To exploit, please use the exploit name from the list and launch the “use_exploit” command which accepts two parameters: the exploit name and the system name.
        </p>

        <img
          src={instructions3}
          alt="Instructions 3"
          className="instructions-image"
          style={{ height: "70%", width: "90%" }}
        />

        <p>
        If unsuccessful in gaining access to the system, then you can execute the same attack again or choose another vulnerability to exploit. After gaining access to the system, you can run the “ls” command to see the files and folders available in the system.
        </p>
        <img
          src={instructions4}
          alt="Instructions 4"
          className="instructions-image"
          style={{ height: "65%", width: "70%" }}
        />

        <p>
        You can now start the next challenge in the study. The description of the task is provided in the README.txt file. You may use ”cat README.txt” command to read the task instructions.
        </p>
        <img
          src={instructions6}
          alt="Instructions 6"
          className="instructions-image"
          style={{ height: "100%", width: "100%" }}
        />
        <p>
        You may require copying files from one directory to another during the task. Please use the “cp” command as shown below to copy content.
        </p>
        <img
          src={instructions7}
          alt="Instructions 7"
          className="instructions-image"
          style={{ height: "60%", width: "70%" }}
        />
        <p>
        You can also choose to logout and terminate the attack anytime you like. After which you may go on to probe, exploit and complete tasks from other systems
        </p>
        <img
          src={instructions5}
          alt="Instructions 5"
          className="instructions-image"
          style={{ height: "30%", width: "40%" }}
        />

        {/* Button to navigate to /video */}
        <br />
        <br />
        <button onClick={navigateToVideo} className="ins-navigate-button">
          Watch Video Instructions
        </button>
      </div>
    </div>
  );
}

export default Instructions;
