import React from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import "./Video.css"; // Ensure the path matches your project structure
import videoFile from "./video/HackIT.mp4"; // Adjust the path as necessary

function VideoComponent() {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate(); // Hook for navigation

  const handleNavigation = () => {
    navigate("/quiz"); // Replace '/your-target-path' with your actual target path
  };

  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [showButton, setShowButton] = useState(false); // New state for controlling the button visibility

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const verifyCookie = async () => {
      /*
      if (!cookies.token) {
        navigate("/login");
      } */
      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);
      return status
        ? console.log(`Hello ${user}`)
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  useEffect(() => {
    // Set timeout to change `showButton` state after 120000 milliseconds (2 minutes)
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 15000); // 120000 milliseconds = 2 minutes

    // Cleanup function to clear the timeout if the component unmounts before the timer finishes
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="video-page">
      <div className="video-container">
        <video className="responsive-video" autoPlay muted controls>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {showButton && (
          <button onClick={handleNavigation} className="navigate-button">
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default VideoComponent;
