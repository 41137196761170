import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./Screening.css"; // Ensure you have appropriate CSS for this form

function createCheckboxInput({ name, options, onChange }) {
  //Component Return
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="checkbox"
            name={option.name ? option.name : name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createRadioInput({ name, options, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="radio"
            name={name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
            required
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createNumberInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="number"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={() => onChange(name.name, name.label, option.label)}
        required
      />
      <br />
      <br />
    </div>
  );
}

function createTextInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="text"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={(e) => onChange(name.name, name.label, e.target.value)}
      />
      <br />
      <br />
    </div>
  );
}

function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}
const Screening = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  // Navigate for routing to different pages
  const navigate = useNavigate();

  // Array for keeping track of answered questions
  const [cookies, removeCookie] = useCookies([]);
  const [inputValue, setInputValue] = useState({});
  const [user, setUsername] = useState("");

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  // Verify and Save Username
  useEffect(() => {
    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);

      // Update inputValue state to include the username
      setInputValue({
        ...inputValue,
        username: user, // Set the username here
      });

      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // Keeping track of changes to variables
  const handleOnChange = (qId, qLabel, aLabel) => {
    setInputValue({
      ...inputValue,
      [qId]: {
        Label: qLabel,
        Answer: [aLabel],
      },
    });
  };

  // Keeping track of errors to form
  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  // Success notifier
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
    setTimeout(() => {
      if (msg == "Thank you for participating!") {
        navigate("/thankyou"); //YOU FAILED
      } else {
        navigate("/demographics");
      }
    }, 2000); // Adjust timing as needed
  };

  let date = new Date();

  // Submit the form to back-end on Success
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const additionDate = {
        createdDate: String(date.toLocaleDateString('en-US', { timeZone: 'America/Denver' })),
        createdTime: String(date.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })),
      };
      const { data } = await axios.post(
        `${apiUrl}/screening`, // Adjust the endpoint as needed
        {
          ...inputValue,
          ...additionDate,
        },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
      handleError("An error occurred while submitting the form.");
    }
  };

  /* Custom value storage for checkbox components */
  const innerCheckboxChange = (qId, qLabel, aLabel) => {
    if (
      inputValue[qId] &&
      inputValue[qId].Answer &&
      inputValue[qId].Answer.includes(aLabel)
    ) {
      //Remove it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId].Answer.filter(
            (answer) => answer !== aLabel
          ),
        },
      }));
    } else {
      //Create and/or add to it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId]
            ? [...prevInputValue[qId].Answer, aLabel]
            : [aLabel],
        },
      }));
    }
  };

  // User Interface Components
  return (
    <div className="survey_form">
      <div className="form_container">
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Screening Questionnaire
        </h2>
        <h3 style={{ color: "#333", fontSize: "1.2rem" }}>
          This brief survey will help us determine if you meet the criteria for
          participation. Please answer the following questions honestly and to
          the best of your ability.
        </h3>
        <form onSubmit={handleSubmit}>
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}

          {/* Question Time */}
          {createRadioInput({
            name: { name: "q1", label: "SQL injection is a technique to:" },
            options: [
              {
                label: "Inject a malicious virus to the database SQL engine",
                value: "q1-1",
                id: "q1-1",
              },
              {
                label:
                  "Inject a security patch to the database SQL engine in response to the discovery of new threats",
                value: "q1-2",
                id: "q1-2",
              },
              {
                label:
                  "Inject a statement that checks the database integrity through a website",
                value: "q1-3",
                id: "q1-3",
              },
              {
                label:
                  "Inject root user privileges to a regular user without using the graphical user interface (GUI) of the database",
                value: "q1-4",
                id: "q1-4",
              },
              {
                label:
                  "Inject a malicious SQL statement to a database through a website",
                value: "q1-5",
                id: "q1-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: { name: "q2", label: "What does SSL stand for?" },
            options: [
              { label: "Systematic Security Level", value: "q2-1", id: "q2-1" },
              { label: "Secret Service Logarithm", value: "q2-2", id: "q2-2" },
              { label: "Secure Sockets Layer", value: "q2-3", id: "q2-3" },
              { label: "Secret Secure Location", value: "q2-4", id: "q2-4" },
              { label: "Standard Security Level", value: "q2-5", id: "q2-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: { name: "q3", label: "What is a Firewall?" },
            options: [
              { label: "Antivirus Software", value: "q3-1", id: "q3-1" },
              { label: "Anti-Phishing software", value: "q3-2", id: "q3-2" },
              {
                label: "A filter for Internet connections",
                value: "q3-3",
                id: "q3-3",
              },
              {
                label: "Software that logs Internet activity",
                value: "q3-4",
                id: "q3-4",
              },
              { label: "An application program", value: "q3-5", id: "q3-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: { name: "q4", label: "What is a proxy server?" },
            options: [
              {
                label:
                  "A server that retrieves data from host servers before sending it to a computer",
                value: "q4-1",
                id: "q4-1",
              },
              {
                label:
                  "A virtual server that can behave like a mail server, Web server or FTP server",
                value: "q4-2",
                id: "q4-2",
              },
              {
                label:
                  "A waiter who never seems to be in the restaurant when your water glass is empty",
                value: "q4-3",
                id: "q4-3",
              },
              {
                label: "A fileserver that stores records",
                value: "q4-4",
                id: "q4-4",
              },
              {
                label: "A computer that hosts web pages directly",
                value: "q4-5",
                id: "q4-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: { name: "q5", label: "What does DDoS stand for?" },
            options: [
              {
                label: "Dangerous DOS open Security",
                value: "q5-1",
                id: "q5-1",
              },
              {
                label: "Digital Defaults of Server",
                value: "q5-2",
                id: "q5-2",
              },
              {
                label: "Distributed Denial of Service",
                value: "q5-3",
                id: "q5-3",
              },
              {
                label: "Defense Department’s Operating System",
                value: "q5-4",
                id: "q5-4",
              },
              {
                label: "Digital Distributed Operating System",
                value: "q5-5",
                id: "q5-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q6",
              label:
                "The difference between passive and reactive Intrusion Detection System (IDS) is that:",
            },
            options: [
              {
                label:
                  "Passive IDS is software based and reactive is hardware based",
                value: "q6-1",
                id: "q6-1",
              },
              {
                label:
                  "Passive IDS provides only alerts and reactive IDS can retaliate by sending malicious code to the attacker",
                value: "q6-2",
                id: "q6-2",
              },
              {
                label: "There are no real differences it just brand names",
                value: "q6-3",
                id: "q6-3",
              },
              {
                label:
                  "Passive IDS is included in a Firewall while reactive IDS is a standalone network component",
                value: "q6-4",
                id: "q6-4",
              },
              {
                label:
                  "Reactive IDS can reprogram the Firewall and passive IDS does not",
                value: "q6-5",
                id: "q6-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: { name: "q7", label: "What is phishing?" },
            options: [
              {
                label:
                  "A decryption method that uses complex algorithms (swarm algorithms)",
                value: "q7-1",
                id: "q7-1",
              },
              {
                label:
                  "A method to acquire personal information such as password, credit card details etc. by disguising as a trustworthy entity in an email",
                value: "q7-2",
                id: "q7-2",
              },
              {
                label:
                  "A way to send swarms of spam mail to millions of people at once",
                value: "q7-3",
                id: "q7-3",
              },
              {
                label: "A game where one catches fish in the sea",
                value: "q7-4",
                id: "q7-4",
              },
              {
                label:
                  "A downloadable program that looks benign but is actually a virus",
                value: "q7-5",
                id: "q7-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q8",
              label:
                "A network site that appears to have valuable information but is in fact isolated and monitored is what kind of security measure?",
            },
            options: [
              { label: "Pseudo-network", value: "q8-1", id: "q8-1" },
              { label: "Honeypot", value: "q8-2", id: "q8-2" },
              { label: "Virtual Network", value: "q8-3", id: "q8-3" },
              { label: "Canary Trap", value: "q8-4", id: "q8-4" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q9",
              label:
                "TCP port 23 is used for the communication between client and server. Which is this protocol?",
            },
            options: [
              { label: "SSH", value: "q9-1", id: "q9-1" },
              { label: "SNMP", value: "q9-2", id: "q9-2" },
              { label: "SMTP", value: "q9-3", id: "q9-3" },
              { label: "Telnet", value: "q9-4", id: "q9-4" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q10",
              label: "What are the port states determined by Nmap?",
            },
            options: [
              {
                label: "Active, open, inactive, standby",
                value: "q10-1",
                id: "q10-1",
              },
              { label: "Open, half-open, closed", value: "q10-2", id: "q10-2" },
              {
                label: "Open, closed, filtered, unfiltered",
                value: "q10-3",
                id: "q10-3",
              },
              { label: "Active, closed, unused", value: "q10-4", id: "q10-4" },
            ],
            onChange: handleOnChange,
          })}

          <button type="submit" className="cont_button">
            Continue
          </button>
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default Screening;
